import Star from '@amo/core/assets/images/AMO_Icons/Star.svg';
import { BulleAide, Question } from '@amo/core/components/containers';
import { Select } from '@amo/core/components/forms';
import {getCRMAutoMin, getTranslation} from '@amo/core/utils/functions.js';
import _ from 'lodash';
import { Field } from 'redux-form';

export const ValeurCrmAuto = (props) => {
    const { nextQuestion, data, changeValue } = props;

    const minCrmAuto = getCRMAutoMin(_.get(data, 'DemandeTarif[ListePersonnes][0][ListePermis][4][DatePermis]'));

    return (
        <Question icon={Star} title={getTranslation('profil.crm.titleAuto')}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-md-6 col-12 my-2'}>
                    <Field
                        name={'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]'}
                        component={Select}
                        onChange={(e) => {
                            changeValue('DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]', '');
                            changeValue('firstValeurCrmAuto', e.target.value);
                        }}
                    >
                        <option value={''}>{getTranslation('profil.crm.selectText')}</option>
                        {Array.from({ length: 157 }, (_, index) => {
                            const code = (0.5 + index * 0.01).toFixed(2);
                            if (code === '1.00') {
                                return (
                                    <option key={index} value={code}>
                                        {getTranslation('profil.crm.noBonusnoMalus')}
                                    </option>
                                );
                            }
                            const bonusMalus = index < 50 ? getTranslation('profil.crm.selectBonus', {bonus: 50 - index}) : getTranslation('profil.crm.selectMalus', {malus: index - 50});
                            return <option key={index} value={code}>{getTranslation('profil.crm.optionsSelect', {code, bonusMalus})}</option>;
                        })}
                    </Field>
                </div>
            </div>

            {_.get(data, 'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]') &&
                Number(_.get(data, 'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]')) < minCrmAuto && (
                    <BulleAide
                        title={getTranslation('warning')}
                        text={getTranslation('profil.crm.help.text', {minCrmAuto})}
                    />
                )}

            <button
                className={`btn btn-primary btn-arrow mt-4 ${
                    _.get(data, 'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]') === null ||
                    _.get(data, 'DemandeTarif[ListePersonnes][0][ValeurCrmAuto]') === ''
                        ? 'disabled'
                        : ''
                }`}
                onClick={() => nextQuestion()}
            >
                {getTranslation('continue')}
            </button>
        </Question>
    );
};